<template>
    <header id="homeHeader" class="home_header">
        <ul class="header_pc dp_flex h_100per" :class="headercss">
            <li class="align_center h_100per home_nav_left_item">
                <img class="logo_img_size cursor" @click="scrollIntoView(null, 0)" :src="logImgSrc" alt="APLAYZ" />
            </li>
            <li class="dp_flex mlr_auto">
                <nav class="home_nav">
                    <ul class="dp_flex h_100per align_center">
                        <li>
                            <button class="nav_btn first" :class="{ active: activeindex === 0 }" @click="scrollIntoView(null, 0)">
                                <slot name="firstbtn">
                                    회사
                                </slot>
                            </button>
                        </li>
                        <li>
                            <button class="nav_btn" :class="{ active: activeindex === 1 }" @click="scrollIntoView('homeSection3', 1)">
                                <slot name="secondtbtn">
                                    기술
                                </slot>
                            </button>
                        </li>
                        <li>
                            <button class="nav_btn" :class="{ active: activeindex === 2 }" @click="scrollIntoView('homeSection4', 2)">
                                <slot name="thirdtbtn">
                                    상품
                                </slot>
                            </button>
                        </li>
                        <li>
                            <button class="nav_btn" :class="{ active: activeindex === 3 }" @click="scrollIntoView('homeSection5', 3)">
                                <slot name="fourthtbtn">
                                    고객사
                                </slot>
                            </button>
                        </li>
                        <li v-if="isshownews">
                            <button
                                class="nav_btn"
                                :class="{ active: activeindex === 4 }"
                                @click="scrollIntoView('homeSection6News', 4)"
                            >
                                뉴스
                            </button>
                        </li>
                        <li v-if="isshowexperience">
                            <button class="nav_btn last" :class="{ active: activeindex === 5 }" @click="goPage('Experience')">
                                미리보기
                            </button>
                        </li>
                    </ul>
                </nav>
            </li>
            <li class="home_nav_right_item">
                <ul class="dp_flex home_btngroup h_100per align_center">
                    <li>
                        <button class="homebtn_inquiry" @click="$emit('open-inquiry')">
                            <slot name="inquirybtn">
                                문의
                            </slot>
                        </button>
                    </li>
                    <li v-if="islogin">
                        <button class="homebtn_login" @click="goLogin">
                            로그인
                        </button>
                    </li>
                    <li class="select_language_wrap">
                        <ul class="align_center" @click="isActiveLanguageSelect = !isActiveLanguageSelect">
                            <li class="select_language_text cursor">Language</li>
                            <li>
                                <img
                                    src="/media/images/home/keyboard_arrow_down.png"
                                    alt="사용 방법"
                                    class="select_arrow_down_img"
                                    :class="{ rotate: isActiveLanguageSelect }"
                                />
                            </li>
                        </ul>

                        <ul class="select_language_list flex_column" v-if="isActiveLanguageSelect" v-click-outside="outSideClick">
                            <li
                                class="align_center select_language_item cursor"
                                @click="goLanguagePage('ko')"
                                :class="{ active: isactivelanguage === 'ko' }"
                            >
                                <span class="active_language" :class="{ active: isactivelanguage === 'ko' }"></span>
                                <span class="select_language_text_active cursor">
                                    <slot name="languagetext1">
                                        한국어
                                    </slot>
                                </span>
                            </li>
                            <li
                                class="align_center select_language_item last cursor"
                                @click="goLanguagePage('en')"
                                :class="{ active: isactivelanguage === 'en' }"
                            >
                                <span class="active_language" :class="{ active: isactivelanguage === 'en' }"></span>
                                <span class="select_language_text_active cursor">
                                    <slot name="languagetext2">
                                        English
                                    </slot>
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="header_mobile dp_flex flex_space_between">
            <li class="align_center h_100per" @click="scrollIntoView(null, 0)">
                <img class="home_aplayz_logo" src="/media/img/logo_white.png" alt="APLAYZ" />
            </li>
            <li class="align_center h_100per">
                <button v-if="islogin" class="homebtn_login" @click="goLogin">
                    로그인
                </button>
                <figure class="align_center ">
                    <img
                        class="hambugerbar_img"
                        @click="isOpenMobileNav = true"
                        src="/media/images/home/hambugerbar.png"
                        alt="hambugerbar.png"
                    />
                </figure>
            </li>

            <!-- #### 모바일 메뉴 #### -->
            <nav class="w_100 mo_nav" v-if="isOpenMobileNav">
                <ul class="mo_menu_list">
                    <li class="mo_menu_list_item first flex_space_between align_center">
                        <img class="mo_logo_img" src="/media/images/home/aplayz_logo_m.png" alt="어플레이즈로고 모바일" />
                        <img
                            class="mo_close_img"
                            @click="isOpenMobileNav = false"
                            src="/media/img/close_btn.png"
                            alt="어플레이즈로고 모바일"
                        />
                    </li>
                    <li class="mo_menu_list_item">
                        <button class="mo_menu_list_item_text cursor" @click="scrollIntoView('homeSection3', 1)">
                            <slot name="secondtbtn">
                                기술
                            </slot>
                        </button>
                    </li>
                    <li class="mo_menu_list_item">
                        <button class="mo_menu_list_item_text cursor" @click="scrollIntoView('homeSection4', 2)">
                            <slot name="thirdtbtn">
                                상품
                            </slot>
                        </button>
                    </li>
                    <li class="mo_menu_list_item">
                        <button class="mo_menu_list_item_text cursor" @click="scrollIntoView('homeSection5', 3)">
                            <slot name="fourthtbtn">
                                고객사
                            </slot>
                        </button>
                    </li>
                    <li class="mo_menu_list_item" v-if="isshownews">
                        <button class="mo_menu_list_item_text cursor" @click="scrollIntoView('homeSection6News', 4)">뉴스</button>
                    </li>
                    <li class="mo_menu_list_item">
                        <button
                            class="mo_menu_list_item_text cursor"
                            @click="
                                $emit('open-inquiry');
                                isOpenMobileNav = false;
                            "
                        >
                            <slot name="inquirybtn">
                                문의
                            </slot>
                        </button>
                    </li>
                    <li class="mo_menu_list_item last">
                        <ul
                            class="flex_space_between align_center"
                            @click="isActiveLanguageSelectMobile = !isActiveLanguageSelectMobile"
                        >
                            <li class="mo_menu_list_item_text">
                                Language
                            </li>
                            <li>
                                <img
                                    src="/media/images/home/keyboard_arrow_down.png"
                                    alt="사용 방법"
                                    class="select_arrow_down_img"
                                    :class="{ rotate: isActiveLanguageSelectMobile }"
                                />
                            </li>
                        </ul>
                    </li>
                    <li class="mo_menu_list_item language_list" v-if="isActiveLanguageSelectMobile">
                        <ul class="flex_column">
                            <li
                                class="align_center select_language_item cursor"
                                @click="goLanguagePage('ko')"
                                :class="{ active: isactivelanguage === 'ko' }"
                            >
                                <span class="active_language" :class="{ active: isactivelanguage === 'ko' }"></span>
                                <span class="select_language_text_active cursor">
                                    <slot name="languagetext1">
                                        한국어
                                    </slot>
                                </span>
                            </li>
                            <li
                                class="align_center select_language_item last cursor"
                                @click="goLanguagePage('en')"
                                :class="{ active: isactivelanguage === 'en' }"
                            >
                                <span class="active_language" :class="{ active: isactivelanguage === 'en' }"></span>
                                <span class="select_language_text_active cursor">
                                    <slot name="languagetext2">
                                        English
                                    </slot>
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </ul>
    </header>
</template>
<script>
import vClickOutside from 'v-click-outside';
export default {
  name: 'HomeHeader',
  emits: ['open-inquiry', 'click-nav', 'click-gopage'],
  props: {
    headercss: {
      type: String,
      default: '',
      required: false
    },
    activeindex: {
      type: Number,
      default: 0,
      required: false
    },
    isshownews: { type: Boolean, required: false, default: true },
    isshowexperience: {
      type: Boolean,
      required: false,
      default: true
    },
    islogin: {
      type: Boolean,
      required: false,
      default: true
    },
    isactivelanguage: {
      type: String,
      required: false,
      default: 'ko'
    }
  },
  directives: { clickOutside: vClickOutside.directive },
  data () {
    return {
      headerHeight: 0,
      isActive: 0,
      isActiveLanguageSelect: false,
      isActiveLanguageSelectMobile: false,
      headerPcEle: null,
      isOpenMobileNav: false,
      logImgSrc: '/media/img/logo_white.png' // /media/img/logo_white.svg, /media/img/logo_pink.png
    };
  },

  mounted () {
    this.headerPcEle = document.querySelector('#homeHeader');
    this.headerHeight = document.querySelector('#homeHeader').clientHeight;
    window.addEventListener('scroll', this.homeHeaderScroll);
  },

  methods: {
    outSideClick () {
      this.isActiveLanguageSelect = false;
    },
    goLanguagePage (language = 'ko') {
      console.log('!');

      if (language === 'ko') {
        this.$router.push({ name: 'Home' }).catch(() => {});
      } else {
        this.$router.push({ name: 'HomeEn' }).catch(() => {});
      }
    },
    homeHeaderScroll () {
      const scrollY = window.scrollY;
      const homeEle = document.querySelector('#homeHeader');
      if (scrollY > this.headerHeight) {
        this.logImgSrc = '/media/img/logo_pink.png';
        homeEle.classList.add('scroll');
      } else if (scrollY <= 0) {
        this.logImgSrc = '/media/img/logo_white.png';
        homeEle.classList.remove('scroll');
      }
    },
    goLogin () {
      this.$router.push({ name: 'Login', params: this.login_param }).catch(() => {});
    },
    scrollIntoView (id, index = 0) {
      this.isOpenMobileNav = false;
      this.$emit('click-nav', {
        id,
        index
      });
      this.isActive = index;
    },
    goPage (name) {
      this.$emit('click-gopage', name);
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.homeHeaderScroll);
  }
};
</script>
<style scoped src="@/assets/css/home/homeheader.css"></style>
